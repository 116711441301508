/* For WebKit browsers */
::-webkit-scrollbar {
    width: 10px;
    
}

::-webkit-scrollbar-track {
    background: #e2e2e2; 
    border-radius: 4px;
}

::-webkit-scrollbar-thumb {
    background: #aeaeae; 
}

::-webkit-scrollbar-thumb:hover {
    background: #6f6f6f; 
}

