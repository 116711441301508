.app-brand-link,
.app-brand-link span{
  font-size: 1.5rem;
  font-weight: 700;
  color: #0e4f31;
}
.app-brand-link span{
  color: #24CD7E;
}
.text-justify {
  text-align: justify;
  text-align-last: justify; /* Ensures the last line is justified as well */
}
.transparent-btn{
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu .app-brand.demo {
  height: 64px;
  margin-top: 12px;
}

.app-brand-logo.demo svg {
  width: 22px;
  height: 38px;
}

.app-brand-text.demo {
  font-size: 1.75rem;
  letter-spacing: -0.5px;
  text-transform: lowercase;
}

.cropper-container{
  width: 100% !important;
  height: 400px !important;
  z-index:  2 !important;
}


/* ! For .layout-navbar-fixed added fix padding top tpo .layout-page */
/* Detached navbar */
.layout-navbar-fixed .layout-wrapper:not(.layout-horizontal):not(.layout-without-menu) .layout-page {
  padding-top: 76px !important;
}
/* Default navbar */
.layout-navbar-fixed .layout-wrapper:not(.layout-without-menu) .layout-page {
  padding-top: 64px !important;
}

/* Navbar page z-index issue solution */
.content-wrapper .navbar {
  z-index: auto;
}
.nav-title{
  font-size: 1.1rem;
  font-weight: 700;
}
.singleInput, .multipleInput {
  display: none;
}
.card-box-icon{
  background-color: #efefef;
  font-size: 1.5rem;
  padding: 0.6rem;
  border-radius: 4px;
}
/*
* Content
******************************************************************************/

.demo-blocks > * {
  display: block !important;
}

.demo-inline-spacing > * {
  margin: 1rem 0.375rem 0 0 !important;
}

/* ? .demo-vertical-spacing class is used to have vertical margins between elements. To remove margin-top from the first-child, use .demo-only-element class with .demo-vertical-spacing class. For example, we have used this class in forms-input-groups.html file. */
.demo-vertical-spacing > * {
  margin-top: 1rem !important;
  margin-bottom: 0 !important;
}
.demo-vertical-spacing.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.demo-vertical-spacing-lg > * {
  margin-top: 1.875rem !important;
  margin-bottom: 0 !important;
}
.demo-vertical-spacing-lg.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.demo-vertical-spacing-xl > * {
  margin-top: 5rem !important;
  margin-bottom: 0 !important;
}
.demo-vertical-spacing-xl.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.rtl-only {
  display: none !important;
  text-align: left !important;
  direction: ltr !important;
}

[dir='rtl'] .rtl-only {
  display: block !important;
}

/*
* Layout demo
******************************************************************************/

.layout-demo-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 1rem;
}
.layout-demo-placeholder img {
  width: 900px;
}
.layout-demo-info {
  text-align: center;
  margin-top: 1rem;
}
.image-popup {
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1; }

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8; }

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0; }



.lottie-container{
  width: 130px;
}

.item-checkbox-container{
  height: 100%;
  width: 100%;
  background-color: rgba(41, 41, 41, 0.493);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0.5rem;
  display: none;
}
.item-checkbox-container .form-check-input{
  margin: 1.5rem;
  height: 1.6rem;
  width: 1.6rem;
}

.image-container {
  position: relative;
  width: 100%;
  height: 225px; /* Max height */
  overflow: hidden;
}

.image-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%; /* Ensures image height fills the container */
  width: 100%;  /* Maintains aspect ratio */
  transform: translate(-50%, -50%); /* Centers the image */
}
